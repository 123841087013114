import React from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { NextSeo } from "next-seo";
import Layout from "../components/Layout";
import { useTranslation } from "next-i18next";

import Intro from "../sections/home/intro";
import Solutions from "../sections/home/solutions";
import CoreValues from "../sections/home/coreValues";
import Usecases from "../sections/home/usecases";

import Ecosystem from "../sections/home/ecosystem";
import Backers from "../sections/home/backers";
import News from "../sections/home/news";
import CTA from "../components/cta";
import FAQ from "../sections/common/faq";
import { SectionSpacing } from "@/utils";

const solutions = [
  {
    imgSrc: "/image/home/solutions/studio.svg",
  },
  {
    imgSrc: "/image/home/solutions/sdk.svg",
  },
  {
    imgSrc: "/image/home/solutions/avs.svg",
  },
];
const usecases = [
  {
    animation: "/lottie/usecase1.json",
    height: 320,
  },
  {
    animation: "/lottie/usecase2.json",
    height: 320,
  },
  {
    animation: "/lottie/usecase3.json",
    height: 260,
  },
  {
    animation: "/lottie/usecase4.json",
    height: 320,
  },
];

function Home() {
  const { t } = useTranslation("home");

  return (
    <Layout isBannerVisible>
      <NextSeo
        title="Private Autonomous Super-Transactions for Web3"
        description="Ava Protocol is an event-driven EigenLayer AVS that seamlessly enables private autonomous transactions for numerous use cases, such as DeFi, NFTs, and games."
      />
      <Intro className="mb-[0px] lg:mb-[40px]" />
      <CoreValues className="mb-[40px] lg:mb-[90px]" />
      <Solutions t={(key) => t(`solutions.${key}`, { returnObjects: true })} items={solutions} />
      <Usecases t={(key) => t(`usecases.${key}`, { returnObjects: true })} items={usecases} />
      <Ecosystem className={SectionSpacing} />
      <News className={SectionSpacing} />
      <Backers className={SectionSpacing} />
      <FAQ t={(key) => t(`faq.${key}`, { returnObjects: true })} className={SectionSpacing} />
      <CTA className="mb-[40px] lg:mb-[60px]" />
      {/* <News refSection={refNews} /> */}
    </Layout>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "home", "header", "footer"])),
    },
  };
}

export default Home;
